.graph-container {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: grab;
}

.graph-container #main-canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.graph-container #labels-canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    user-select: none;
    outline: none;
    pointer-events: none;
    -webkit-tap-highlight-color: transparent;
}


.graph-container .clickable {
    cursor: pointer;
}

.graph-container .grabbable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.graph-container .grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

/* https://loading.io/css/ */

.loading-dual-ring {
    display: inline-block;
    width: 40px;
    height: 40px;
    pointer-events: none;
}

.loading-dual-ring:after {
    box-sizing: border-box;
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
    border-color: #63b7f7 transparent #63b7f7 transparent;
    animation: loading-dual-ring 1.2s linear infinite;
}

@keyframes loading-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}