.graph-container {
  width: 100%;
  height: 100%;
  cursor: grab;
  position: relative;
}

.graph-container #main-canvas {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  display: block;
  position: absolute;
  inset: 0;
}

.graph-container #labels-canvas {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  display: block;
  position: absolute;
  inset: 0;
}

.graph-container .clickable {
  cursor: pointer;
}

.graph-container .grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.graph-container .grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.loading-dual-ring {
  width: 40px;
  height: 40px;
  pointer-events: none;
  display: inline-block;
}

.loading-dual-ring:after {
  box-sizing: border-box;
  content: " ";
  width: 100%;
  height: 100%;
  border: 4px solid #63b7f7;
  border-color: #63b7f7 #0000;
  border-radius: 50%;
  animation: loading-dual-ring 1.2s linear infinite;
  display: block;
}

@keyframes loading-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.toastify {
  color: #fff;
  opacity: 0;
  cursor: pointer;
  max-width: calc(50% - 20px);
  z-index: 2147483647;
  background: linear-gradient(135deg, #73a5ff, #5477f5);
  border-radius: 2px;
  padding: 12px 20px;
  text-decoration: none;
  transition: all .4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  position: fixed;
  box-shadow: 0 3px 6px -1px #0000001f, 0 10px 36px -4px #4d60e84d;
}

.toastify.on {
  opacity: 1;
}

.toast-close {
  color: #fff;
  cursor: pointer;
  opacity: .4;
  background: none;
  border: 0;
  padding: 0 5px;
  font-family: inherit;
  font-size: 1em;
}

.toastify-right {
  right: 15px;
}

.toastify-left {
  left: 15px;
}

.toastify-top {
  top: -150px;
}

.toastify-bottom {
  bottom: -150px;
}

.toastify-rounded {
  border-radius: 25px;
}

.toastify-avatar {
  width: 1.5em;
  height: 1.5em;
  border-radius: 2px;
  margin: -7px 5px;
}

.toastify-center {
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: -moz-fit-content;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 360px) {
  .toastify-right, .toastify-left {
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

/*# sourceMappingURL=index.c114f524.css.map */
